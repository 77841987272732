@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bg-home {
    background: url("assets/img/sunset.jpg") no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg-side {
    background: url("assets/img/cornRipe.jpeg") no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg-service {
    background: url("assets/img/farmerInField.jpg") no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg-products {
    background: url("assets/img/cereals.jpg") no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg-about {
    background: url("assets/img/farmerResting.jpg") no-repeat;
    background-size: cover;
    background-position: center;
  }
}

::-webkit-scrollbar {
  height: 100%;
  width: 0.75rem;
  background-color: initial;
}
body::-webkit-scrollbar {
  --tw-bg-opacity: 1;
  background-color: #344054;
}
::-webkit-scrollbar-track {
  background-color: initial;
}
::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  border: 0.125rem solid #dbdfe6;
  background-clip: content-box;
  background-color: #dbdfe6;
}

::-webkit-scrollbar-button {
  display: none;
}

body {
  font-family: "Inter", sans-serif !important;
  color: #333333;
  font-size: 14px;
}

.pl {
  display: block;
  width: 6.25em;
  height: 6.25em;
}
.pl__ring,
.pl__ball {
  animation: ring 2s ease-out infinite;
}
.pl__ball {
  animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 10%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
}

/* Animation */
@keyframes ring {
  from {
    stroke-dasharray: 0 257 0 0 1 0 0 258;
  }
  25% {
    stroke-dasharray: 0 0 0 0 257 0 258 0;
  }
  50%,
  to {
    stroke-dasharray: 0 0 0 0 0 515 0 0;
  }
}
@keyframes ball {
  from,
  50% {
    animation-timing-function: ease-in;
    stroke-dashoffset: 1;
  }
  64% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -109;
  }
  78% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -145;
  }
  92% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -157;
  }
  57%,
  71%,
  85%,
  99%,
  to {
    animation-timing-function: ease-out;
    stroke-dashoffset: -163;
  }
}
